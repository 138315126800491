<template>
  <section title="">
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                xl="9"
                md="9"
              >
                <h2>Membros</h2>
              </b-col>
              <b-col
                md="3"
              >
                <b-button
                  v-b-modal.addmembros
                  variant="outline-primary"
                  class="mb-75"
                  fixed
                  block
                  @click="adicionarMembro"
                > Adicionar Membro
                </b-button>
              </b-col>
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label === 'Nome'">
                      <b-button
                        v-b-modal.editactt
                        @click="editarUsuario(item.usuario)"
                      >{{ item.usuario.name }}</b-button>
                    </slot>
                    <slot v-if="column.label === 'Ações'">
                      <b-button
                        v-b-modal.addmembros
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        size="sm"
                        class="btn-icon rounded-circle mr-1"
                        @click="EditarMembros(item)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        size="sm"
                        class="btn-icon rounded-circle"
                        @click="DeletarDados(item)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label === 'Status'">
                      <b-badge
                        :variant="NomeStatus(item).cor"
                      >
                        {{ NomeStatus(item).msg }}
                      </b-badge>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
      <b-modal
        v-if="mostraPopUp"
        id="addmembros"
        title="Adicione um Novo Membro"
        hide-footer
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Nome Usuario"
              label-for="nomeuser"
            >
              <b-form-input
                id="nomeuser"
                v-model="nomeUsuarios"
                placeholder="Digite o nome do Usuario"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Email do Usuario"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="emailUsuarios"
                placeholder="Email do Usuario"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-checkbox
              v-model="mostraSenha"
            >
              Alterar senha ?
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="mostraSenha"
          >
            <b-form-group
              label="senha do Usuario"
              label-for="senha"
            >
              <b-form-input
                id="senha"
                v-model="senhaUsuario"
                placeholder="Senha do Usuario"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="SalvarDados"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        v-if="modelEditaCtt"
        id="editactt"
        title="Empresas Gerência"
        hide-footer
      >
        <b-row>
          <b-col>
            <!-- custom checkbox -->

            <b-form-group label="Quais empresas esse úsuario administra ?">
              <b-form-checkbox
                v-for="option in check_options"
                :key="option.value"
                v-model="check_selected"
                :value="option.value"
                name="flavour-3a"
              >
                {{ option.text }}
              </b-form-checkbox>
              <br>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="SalvarGerencia"
              >
                Salvar
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-card></section>

</template>
<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { VBToggle } from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      idUsuario: '',
      nomeUsuarios: '',
      emailUsuarios: '',
      senhaUsuario: '',
      mostraPopUp: false,
      modelEditaCtt: false,
      mostraSenha: false,
      prefixoEmpresa: '',
      check_selected: [],
      check_options: [],
      cttGerencia: {},
      url: `admin/usuarios/${JSON.parse(localStorage.getItem('empresa')).id}`,
      data: {},
      dadositem: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      opcoesTipo: [
        { value: 1, text: 'Ativo' },
        { value: 2, text: 'Inativo' },
      ],
      columns: [
        {
          label: 'Nome',
          orderable: true,
        },
        {
          label: 'Email',
          name: 'usuario.email',
          orderable: true,
        },
        {
          label: 'Status',
          orderable: true,
        },
        {
          label: 'Ações',
          filterable: false,
        },
      ],
    }
  },
  beforeMount() {
    this.empresa = JSON.parse(localStorage.getItem('empresa'))
  },
  created() {
    this.BuscarDados(this.url)
  },
  methods: {
    async SalvarGerencia() {
      const obj = {
        id_user: this.cttGerencia.id,
        empresas: this.check_selected,
      }
      await this.$http.post('admin/salva_emp', obj)
      this.reloadTable()
      this.modelEditaCtt = false
    },
    async editarUsuario(ctt) {
      this.check_selected = []
      this.cttGerencia = ctt
      this.modelEditaCtt = true
      const TodasEmpresas = []
      const empresasParticipa = []
      await this.$http.get(`admin/lista_emp/${ctt.id}`).then(resp => {
        resp.data.emp_participa.forEach(participa => {
          empresasParticipa.push(participa.id_empresa)
        })
        this.check_selected = empresasParticipa
        resp.data.empresas.forEach(empresas => {
          TodasEmpresas.push({
            value: empresas.id,
            text: empresas.nome,
          })
        })
        this.check_options = TodasEmpresas
      })
    },
    EditarMembros(item) {
      this.mostraSenha = false
      localStorage.setItem('dados_menbros', JSON.stringify(item))
      const dados = JSON.parse(localStorage.getItem('dados_menbros'))
      this.idUsuario = dados.usuario.id
      this.nomeUsuarios = dados.usuario.name
      this.emailUsuarios = dados.usuario.email
      this.mostraPopUp = true
    },
    adicionarMembro() {
      this.mostraSenha = true
      localStorage.setItem('dados_menbros', JSON.stringify([]))
      this.idUsuario = null
      this.nomeUsuarios = ''
      this.emailUsuarios = ''
      this.mostraPopUp = true
    },
    ZerarCampos() {
      const obj = {
        idUser: this.idUsuario,
        nome: this.nomeUsuarios,
        email: this.emailUsuarios,
        password: this.senhaUsuario,
        id_empresa: this.empresa.id,
      }
      this.idUsuario = null
      this.nomeUsuarios = null
      this.emailUsuarios = null
      this.senhaUsuario = null
      return obj
    },
    async SalvarDados() {
      this.mostraPopUp = false
      const obj = this.ZerarCampos()
      if (this.id === null) {
        await this.$http.post('admin/usuarios', obj).then(resp => {
          this.data = resp.data
          this.reloadTable()
        })
        return
      }
      await this.$http.post('admin/usuarios', obj).then(resp => {
        this.data = resp.data
        this.reloadTable()
      })
    },
    NomeStatus(item) {
      if (item.status === 2) {
        return { cor: 'light-danger', msg: 'Inativo' }
      }
      if (item.status === 1) {
        return { cor: 'light-success', msg: 'Ativo' }
      }
      if (item.status === 0) {
        return { cor: 'light-warning', msg: 'Fornecedor' }
      } return 'Sem Status'
    },
    BuscarDados(url = this.url, options = this.tableProps) {
      this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    DeletarDados(item) {
      this.$http.delete(`admin/usuarios/${item.id_user}`)
      this.reloadTable()
      return ['excluido com sucesso']
    },
  },
}
</script>
